<template>
  <v-container fluid style="height: calc(100vh - 48px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4 mb-7">
      <v-col class="d-flex">
        <h1>{{ $t(`middleware.role.title`) }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            elevation="2"
            color="green"
            dark
            medium
            class="ml-auto"
            :to="{name: 'roles.create'}"
            v-if="mxware.can('role-create')"
        >
          <v-icon size="28">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" lg="3" v-for="(role, index) in roles" :key="index">
        <v-card class="mx-card-role">
          <v-card-title>{{ role.display_name }}</v-card-title>
          <v-card-subtitle>{{ role.name }}</v-card-subtitle>
          <router-link tag="button" v-if="mxware.can('role-update')" :to="{name: 'roles.edit', params: {id: role.id}}" class="fl_btn_edit">
            <v-icon size="20" color="blue-grey darken-4">
              mdi-pencil-outline
            </v-icon>
          </router-link>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  data() {
    return {
      preloader: true,
      roles: []
    }
  },

  mounted() {
    this.$store.dispatch('rolesLaratrust').then(() => {
      this.roles = this.$store.getters.roles_laratrust.data
      this.preloader = false
    })
  },
  methods: {}
}
</script>

<style lang="scss">
  .mx-card-role {
    &:hover {
      .fl_btn_edit {
        opacity: 1;
      }
    }
  }
  .fl_btn_edit {
    cursor: pointer;
    opacity: 0;
    transition: opacity 250ms ease;
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>